import React from "react";
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n";
import { useLocation } from "@reach/router";
import clsx from "clsx";
import en from "../../locales/en/translations.json";
import fr from "../../locales/fr/translations.json";
import zh from "../../locales/zh/translations.json";

const resolve = (path: string, obj: any, separator = ".") => {
  const crawl = (prev: any, curr: any): any => prev && prev[curr];
  const trans = path.split(separator).reduce<string>(crawl, obj);
  return typeof trans === "undefined" ? path : trans;
};

const useI18next = () => {
  const location = useLocation();
  const { locale, config } = useLocalization();
  return {
    // FIXME: load translation files dynamically via context & dynamic imports
    // to save a couple more ko
    t: (text: string): string => {
      switch (locale) {
        case "fr":
          return resolve(text, fr);
        case "zh":
          return resolve(text, zh);
        default:
          return resolve(text, en);
      }
    },
    language: String(locale),
    languages: config.map((lng: any) => lng.code),
    originalPath: location.pathname.replace(
      new RegExp(`^/${locale}/|^/${locale}$`),
      "/",
    ),
  };
};

function CustomLink(props: {
  [x: string]: any;
  to: any;
  language?: string;
  className?: string;
}): JSX.Element {
  // Retrieve current locale from context
  const { language } = useI18next();

  let finalLanguage = props.language || language;
  let disabled = false;

  // List of allowed URLs for locales where not all are allowed
  const URLs: { [key: string]: string[] } = { zh: ["^/$", "^/about/?$"] };

  if (
    URLs[finalLanguage] &&
    !URLs[finalLanguage].find((rule) => props.to.match(new RegExp(rule)))
  ) {
    // Link points to a page that does not exist in language "finalLanguage"
    if (props.language) {
      // Target language was specified explicitly, deactivate the link
      disabled = true;
    } else {
      // Target language was implicit, fallback to EN
      finalLanguage = "en";
    }
  }

  return (
    <LocalizedLink
      {...props}
      language={finalLanguage}
      to={props.to}
      className={clsx(
        props.className,
        disabled && "pointer-events-none opacity-50",
      )}
    />
  );
}

// Mock i18next & co API here
export { useI18next, CustomLink as Link };
export type TFunction = any;
