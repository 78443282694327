import React, { FunctionComponent } from "react";

type Props = {
  className: string;
};

const Twitter: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 19 15" className={className}>
    <path
      fill="currentColor"
      d="M18.52.28c-.77.47-1.55.75-2.51.94A3.84 3.84 0 0013.21 0a3.82 3.82 0 00-3.76 4.6 11.3 11.3 0 01-8-3.94 3.4 3.4 0 00-.58 1.96c0 1.32.67 2.44 1.73 3.2a3.4 3.4 0 01-1.73-.48v.1a3.86 3.86 0 003.08 3.75c-.29.1-.67.1-1.06.1-.29 0-.48 0-.77-.1A3.84 3.84 0 005.8 11.8a7.81 7.81 0 01-4.83 1.6c-.28 0-.57 0-.96-.1A11.66 11.66 0 005.98 15a10.9 10.9 0 0011.1-10.78v-.47A7.56 7.56 0 0019 1.78c-.68.28-1.45.47-2.22.56A3.67 3.67 0 0018.52.28z"
    />
  </svg>
);

export default Twitter;
