import React, { FunctionComponent } from "react";
import { Link, useI18next } from "../../utils/i18n";
import LogoEnea from "../Icons/LogoEnea";
import LinkedIn from "../Icons/LinkedIn";
import Twitter from "../Icons/Twitter";
import { ROUTES } from "../../constants";
import LongArrow from "../Icons/LongArrow";
import { headerLink, active } from "./index.module.css";

export type Props = {
  backLink?: string;
};

const HeaderDesktop: FunctionComponent<Props> = ({ backLink }) => {
  const { language, t } = useI18next();

  return (
    <header className="hidden md:block bg-mine-shaft relative z-20">
      <div className="constrained h-[108px] flex items-center">
        {backLink ? (
          <Link
            to={backLink}
            className="btn flex items-center pl-0 font-medium text-white border-transparent"
          >
            <LongArrow className="h-2.5 mr-[15px] text-neon-green" /> Back
          </Link>
        ) : (
          <Link to={ROUTES.home.path} title={t("header.link.home")}>
            <LogoEnea className="w-[151px] text-white" />
          </Link>
        )}

        {!backLink && (
          <nav className="flex self-stretch ml-[76px]">
            <Link
              to={ROUTES.about.path}
              className={headerLink}
              activeClassName={active}
            >
              {t("header.link.about")}
            </Link>
            <Link
              to={ROUTES.publications.path}
              className={headerLink}
              activeClassName={active}
            >
              {t("header.link.publications")}
            </Link>
            <a
              href={ROUTES.careers.url(language)}
              className={headerLink}
              rel="noreferrer noopener"
              target="_blank"
            >
              {t("header.link.careers")}
            </a>
          </nav>
        )}

        <div className="ml-auto flex items-center">
          <a
            href={ROUTES.linkedIn.url()}
            target="_blank"
            rel="noopener noreferrer"
            className="hidden lg:inline text-cutty-sark hover:text-silver p-3"
            title={ROUTES.linkedIn.name}
          >
            <LinkedIn className="h-4" />
          </a>
          <a
            href={ROUTES.twitter.url()}
            target="_blank"
            rel="noopener noreferrer"
            className="hidden lg:inline text-cutty-sark hover:text-silver p-3"
            title={ROUTES.twitter.name}
          >
            <Twitter className="h-4" />
          </a>
          <a href={ROUTES.contact.url()} className="btn btn-primary ml-8">
            {t("header.link.contact")}
          </a>
        </div>
      </div>
    </header>
  );
};

export default HeaderDesktop;
