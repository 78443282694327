import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const LongArrow: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 10 10" className={className}>
    <path
      fill="currentColor"
      d="M5.44.18c.25.25.25.64 0 .89l-3.3 3.3h7.24a.62.62 0 110 1.25H2.13l3.31 3.31a.62.62 0 11-.88.89L.18 5.44a.62.62 0 010-.88L4.56.18a.62.62 0 01.88 0z"
    />
  </svg>
);

export default LongArrow;
