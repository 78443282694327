import React, { FunctionComponent, useState } from "react";
import { Link, useI18next, TFunction } from "../../utils/i18n";

import LogoEnea from "../Icons/LogoEnea";
import Arrow from "../Icons/Arrow";
import { ROUTES, Route, ExternalRoute } from "../../constants";
import LongArrow from "../Icons/LongArrow";
import LanguageSwitcher from "../LanguageSwitcher";
import { headerLink, active } from "./index.module.css";

export type Props = {
  backLink?: string;
};

const getLink = (route: Route, t: TFunction): JSX.Element => (
  <Link to={route.path} className={headerLink} activeClassName={active}>
    {t(`header.link.${route.i18nKey}`)}
  </Link>
);

const getExternalLink = (
  link: ExternalRoute,
  t: TFunction,
  lng: string,
): JSX.Element => (
  <a
    href={link.url(lng)}
    target="_blank"
    rel="noreferrer noopener"
    className={headerLink}
  >
    {t(`header.link.${link.i18nKey}`)}
  </a>
);

const HeaderMobile: FunctionComponent<Props> = ({ backLink }) => {
  const { language, t } = useI18next();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  if (backLink) {
    return (
      <header className="md:hidden bg-mine-shaft h-header-mobile flex items-center justify-between">
        <Link
          to={backLink}
          className="btn flex items-center pl-5 font-medium text-white border-transparent"
        >
          <LongArrow className="h-2.5 mr-[15px] text-neon-green" />
          {t("header.back")}
        </Link>
      </header>
    );
  }

  return (
    <header className="md:hidden bg-mine-shaft">
      <div className="px-5 h-header-mobile flex items-center justify-between">
        {menuOpen ? (
          <LanguageSwitcher openOn="bottom" />
        ) : (
          <Link to={ROUTES.home.path} title={t("header.link.home")}>
            <LogoEnea className="w-24 text-white" />
          </Link>
        )}
        <button
          onClick={() => setMenuOpen((open) => !open)}
          className="btn pr-0 text-white border-transparent"
        >
          {menuOpen ? (
            t("header.close")
          ) : (
            <span className="flex items-center font-medium">
              {t("header.menu")}
              <Arrow className="h-[5px] ml-2.5 text-neon-green relative top-px" />
            </span>
          )}
        </button>
      </div>
      {menuOpen && (
        <>
          <nav className="mx-5 py-20 border-t-2 border-b-2 border-neon-green">
            <h2 className="block uppercase text-oslo-grey text-xs font-bold">
              {t("header.main")}
            </h2>
            {getLink(ROUTES.home, t)}
            {getLink(ROUTES.about, t)}
            {getLink(ROUTES.publications, t)}
            {getExternalLink(ROUTES.careers, t, language)}
            {getExternalLink(ROUTES.contact, t, language)}
            {getLink(ROUTES.legal, t)}
            <h2 className="block mt-20 uppercase text-oslo-grey text-xs font-bold">
              {t("header.social-media")}
            </h2>
            {getExternalLink(ROUTES.linkedIn, t, language)}
            {getExternalLink(ROUTES.twitter, t, language)}
          </nav>
        </>
      )}
    </header>
  );
};

export default HeaderMobile;
