import React, { FunctionComponent } from "react";
import { Link, useI18next, TFunction } from "../../utils/i18n";
import { ExternalRoute, Route, ROUTES } from "../../constants";
import LinkedIn from "../Icons/LinkedIn";
import Twitter from "../Icons/Twitter";
import LanguageSwitcher from "../LanguageSwitcher";

export type Props = {};

const getLink = (route: Route, t: TFunction): JSX.Element => (
  <Link to={route.path} className="link mx-4 p-2 text-white">
    {t(`header.link.${route.i18nKey}`)}
  </Link>
);

const getExternalLink = (
  route: ExternalRoute,
  t: TFunction,
  lng: string,
): JSX.Element => (
  <a
    href={route.url(lng)}
    className="link mx-4 p-2 text-white"
    rel="noreferrer noopener"
    target="_blank"
  >
    {t(`header.link.${route.i18nKey}`)}
  </a>
);

const Footer: FunctionComponent<Props> = () => {
  const { language, t } = useI18next();

  return (
    <footer className="bg-black text-sm">
      <div className="min-h-[113px] constrained flex items-center justify-between flex-wrap">
        <div className="w-full xl:w-auto py-4 text-oslo-grey text-roboto font-medium text-center text-sm tracking-wide">
          {t("footer.copy")}{" "}
          <span className="whitespace-nowrap">{t("footer.rights")}</span>
        </div>
        <nav className="hidden md:flex items-center justify-center w-full xl:w-auto py-4">
          {getExternalLink(ROUTES.contact, t, language)}
          {getExternalLink(ROUTES.careers, t, language)}
          {getLink(ROUTES.legal, t)}
          <LanguageSwitcher openOn={"top"} className="ml-4 mr-12" />
          <a
            href={ROUTES.linkedIn.url()}
            target="_blank"
            rel="noopener noreferrer"
            className="text-cutty-sark hover:text-silver p-3"
            title={ROUTES.linkedIn.name}
          >
            <LinkedIn className="h-4" />
          </a>
          <a
            href={ROUTES.twitter.url()}
            target="_blank"
            rel="noopener noreferrer"
            className="text-cutty-sark hover:text-silver p-3"
            title={ROUTES.twitter.name}
          >
            <Twitter className="h-4" />
          </a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
