import React, { FunctionComponent, useState } from "react";
import { Link, useI18next } from "../../utils/i18n";
import FlagEnglish from "../Icons/FlagEnglish";
import FlagFrench from "../Icons/FlagFrench";
import Arrow from "../Icons/Arrow";
import FlagChinese from "../Icons/FlagChinese";
import clsx from "clsx";

export type Props = {
  openOn: "bottom" | "top";
  className?: string;
};

const flagClassName = "h-[15px] pr-[11px]";
const buttonClassName =
  "flex items-center h-[40px] text-roboto font-medium text-sm tracking-wide text-white";

const getFlagAndText = (lng: string) => {
  switch (lng) {
    case "fr": {
      return (
        <>
          <FlagFrench className={flagClassName} /> Français
        </>
      );
    }
    case "en": {
      return (
        <>
          <FlagEnglish className={flagClassName} /> English
        </>
      );
    }
    case "zh": {
      return (
        <>
          <FlagChinese className={flagClassName} /> 中文
        </>
      );
    }
  }
};

const LanguageSwitcher: FunctionComponent<Props> = ({ openOn, className }) => {
  const { languages, originalPath, language } = useI18next();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={clsx(className, "relative")}>
      <button
        className={buttonClassName}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        {getFlagAndText(language)}
        <Arrow
          className={clsx(
            open && "rotate-180",
            "h-[5px] ml-2.5 text-abbey shrink-0",
          )}
        />
      </button>
      <div
        className={clsx(
          !open && "hidden",
          openOn === "top" && "top-0 -translate-y-full",
          "absolute left-0 w-[222px] py-[15px] bg-abbey rounded-card shadow-card",
        )}
      >
        {languages.map((lng: string) => {
          return (
            <Link
              key={lng}
              to={originalPath}
              language={lng}
              activeClassName="bg-black bg-opacity-[.15]"
              className={clsx(
                buttonClassName,
                "w-full pl-[25px] hover:bg-black hover:bg-opacity-[.35]",
              )}
            >
              {getFlagAndText(lng)}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
