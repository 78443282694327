import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const FlagEnglish: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 18 13" className={className}>
    <path fill="#0076FF" d="M0 0h18v13H0z" />
    <path fill="#fff" d="M11 13h-1V0h1zM8 13H7V0h1z" />
    <path fill="#fff" d="M18 8.5H0v-1h18zM18 5.5H0v-1h18z" />
    <path
      fill="#fff"
      d="M9.5 5.3L0-.2.5-1 10 4.5zM8.5 7.1L-1 1.6l.5-.9L9 6.2z"
    />
    <path fill="#ED4925" d="M-.5.7L0-.2l9.5 5.5-.5.9z" />
    <path
      fill="#fff"
      d="M9 6.2L18.5.7l.5.9L9.5 7zM8 4.5L17.5-1l.5.9-9.5 5.5z"
    />
    <path fill="#ED4925" d="M18-.2l.5.9L9 6.2l-.5-.9z" />
    <path
      fill="#fff"
      d="M9 6.8l-9.5 5.5-.5-.8L8.5 6zM10 8.6L.5 14l-.5-.9 9.5-5.5z"
    />
    <path fill="#ED4925" d="M0 13.2l-.5-.9L9 6.8l.5.9z" />
    <path
      fill="#fff"
      d="M8.5 7.7l9.5 5.5-.5.9L8 8.6zM9.5 6l9.5 5.5-.5.9L9 6.9z"
    />
    <path fill="#ED4925" d="M18.5 12.3l-.5.9-9.5-5.5.5-.9z" />
    <path fill="#ED4925" d="M8 0h2v13H8z" />
    <path fill="#ED4925" d="M0 7.5v-2h18v2z" />
  </svg>
);

export default FlagEnglish;
