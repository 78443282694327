import React from "react";
import { useI18next } from "../../utils/i18n";

import Header from "../Header";
import Footer from "../Footer";
import { internetExplorerDeprecatedBanner } from "./index.module.css";

type Props = {
  backLink?: string;
  fullscreen?: boolean;
};

const Layout: React.FC<Props> = ({ children, backLink, fullscreen }) => {
  const { t } = useI18next();

  return (
    <div className={fullscreen ? "min-h-screen flex flex-col" : ""}>
      <div
        className={`${internetExplorerDeprecatedBanner} hidden text-black p-8 font-bold text-lg`}
        style={{ background: "yellow" }}
      >
        {t("internet-explorer-banner.deprecation-message")}
      </div>
      <Header backLink={backLink} />
      <main className={fullscreen ? "flex flex-col flex-1" : ""}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
