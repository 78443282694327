import React, { FunctionComponent } from "react";

type Props = {
  className: string;
};

const LinkedIn: FunctionComponent<Props> = ({ className }) => (
  <svg className={className} viewBox="0 0 18 16">
    <path
      fill="currentColor"
      d="M4.4 1.9c0 1-1 1.9-2.2 1.9C1 3.8 0 2.9 0 1.9 0 .9 1 0 2.2 0c1.3 0 2.2.9 2.2 1.9zm9.4 3c-2.8 0-3.5 1.8-3.5 1.8V5.4H6.8L6.7 16h3.6V9.4s.3-1.6 2-1.6c1.9 0 2.1 1.7 2.1 1.7V16H18V9.2c-.2-2.8-1.5-4.3-4.2-4.3zM0 16h4.4V5.3H0V16z"
    />
  </svg>
);

export default LinkedIn;
