export type Route = {
  name: string;
  path: string;
  i18nKey: string;
};

export type ExternalRoute = {
  name: string;
  url: (lng?: string) => string;
  i18nKey: string;
};

export type Routes = {
  home: Route;
  about: Route;
  publications: Route;
  publication: Route;
  careers: ExternalRoute;
  contact: ExternalRoute;
  legal: Route;
  linkedIn: ExternalRoute;
  twitter: ExternalRoute;
};

export const ROUTES: Routes = {
  home: {
    name: "Homepage",
    path: "/",
    i18nKey: "home",
  },
  about: {
    name: "About us",
    path: "/about",
    i18nKey: "about",
  },
  publications: {
    name: "Publications",
    path: "/publications",
    i18nKey: "publications",
  },
  publication: {
    name: "Publication",
    path: "/publication",
    i18nKey: "publication",
  },
  careers: {
    name: "Careers",
    url: (lng: string | undefined): string => {
      if (lng === "fr") {
        return "https://www.welcometothejungle.com/fr/companies/enea-consulting";
      }
      return "https://www.welcometothejungle.com/en/companies/enea-consulting";
    },
    i18nKey: "careers",
  },
  contact: {
    name: "Contact us",
    url: () => "mailto:hello@enea-consulting.com",
    i18nKey: "contact",
  },
  legal: {
    name: "Legal",
    path: "/legal",
    i18nKey: "legal",
  },
  linkedIn: {
    name: "Linkedin",
    url: () => "https://www.linkedin.com/company/blunomy/",
    i18nKey: "linkedin",
  },
  twitter: {
    name: "Twitter",
    url: () => "https://twitter.com/enea_consulting",
    i18nKey: "twitter",
  },
};

export type Office = {
  location: string;
  addressLine1: string;
  addressLine2: string;
  email: string;
};

export const OFFICES: Array<Office> = [
  {
    location: "Paris",
    addressLine1: "17-21 rue Saint Fiacre,",
    addressLine2: "75002 Paris, France",
    email: "paris@enea-consulting.com",
  },
  {
    location: "London",
    addressLine1: "Mappin House, 4 Winsley Street,",
    addressLine2: "London, W1W 8HF, United Kingdom",
    email: "london@enea-consulting.com",
  },
  {
    location: "Singapore",
    addressLine1: "168, #12-01 Robinson Road, Capital Tower,",
    addressLine2: "068912, Singapore",
    email: "singapore@enea-consulting.com",
  },
  {
    location: "Hong Kong",
    addressLine1: "One Taikoo Place 23/F - 979 King's Road,",
    addressLine2: "Quarry Bay, Hong Kong",
    email: "hongkong@enea-consulting.com",
  },
  {
    location: "Melbourne",
    addressLine1: "Level 12, 360 Elizabeth Street,",
    addressLine2: "Melbourne VIC 3000, Australia",
    email: "melbourne@enea-consulting.com",
  },
  {
    location: "Sydney",
    addressLine1: "Level 10, 580 George Street,",
    addressLine2: "Sydney NSW 2000, Australia",
    email: "sydney@enea-consulting.com",
  },
];
