import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const FlagFrench: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 18 13" className={className}>
    <path fill="#fff" d="M0 0h18v13H0z" />
    <path fill="#0076FF" d="M0 0h6v13H0z" />
    <path fill="#ED4925" d="M12 0h6v13h-6z" />
  </svg>
);

export default FlagFrench;
