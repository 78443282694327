import React, { FunctionComponent } from "react";

type Props = {
  className: string;
};

const Arrow: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 8 5" className={className}>
    <path
      fill="currentColor"
      d="M.33 1.86l2.89 2.82c.43.43 1.13.43 1.57 0l2.88-2.82c.7-.68.2-1.86-.79-1.86H1.11C.11 0-.37 1.18.33 1.86z"
    />
  </svg>
);

export default Arrow;
