import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const FlagChinese: FunctionComponent<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 20"
    className={className}
  >
    <defs>
      <path
        id="s"
        d="M0,-1 0.587785,0.809017 -0.951057,-0.309017H0.951057L-0.587785,0.809017z"
        fill="#FFFF00"
      />
    </defs>
    <rect width="30" height="20" fill="#EE1C25" />
    <use xlinkHref="#s" transform="translate(5,5) scale(3)" />
    <use xlinkHref="#s" transform="translate(10,2) rotate(23.036243)" />
    <use xlinkHref="#s" transform="translate(12,4) rotate(45.869898)" />
    <use xlinkHref="#s" transform="translate(12,7) rotate(69.945396)" />
    <use xlinkHref="#s" transform="translate(10,9) rotate(20.659808)" />
  </svg>
);

export default FlagChinese;
