import React, { FunctionComponent } from "react";
import HeaderDesktop from "../HeaderDesktop";
import HeaderMobile from "../HeaderMobile";

export type Props = {
  backLink?: string;
};

const Header: FunctionComponent<Props> = ({ backLink }) => (
  <>
    <HeaderDesktop backLink={backLink} />
    <HeaderMobile backLink={backLink} />
  </>
);

export default Header;
